export interface CardType {
  title: string,
  text: string,
  image: any,
  link?: string,
}

interface CardProps { info: CardType }

export const Card = ({ info }: CardProps) => {
  return (
    <section className="relative w-full justify-self-center h-min px-10 py-6 flex flex-col items-center justify-around rounded-lg hover:scale-105 hover:shadow-xl hover:shadow-purple-400 bg-purple-50 transition-all duration-300">
      <h2 className="text-xl h-12 font-bold whitespace-nowrap notranslate">{info.title}</h2>
      <div className="h-32">
        <picture> 
          <img className="h-32" src={info.image} alt="card img" />
        </picture>
      </div>
      <p className="pt-5 xl:h-32">{info.text}</p>
    </section>
  )
}
