import { Project } from "./ProjectContainer";

export interface ProjectProps {
  project: Project;
}

export const ProjectLeft = ({ project }: ProjectProps) => {
  return (
    <div className="xl:w-screen py-5 xl:h-min relative xl:right-1/2 transform xl:translate-x-[400px] bg-gradient-to-r from-blue-700 to-purple-500 rounded-2xl shadow-lg shadow-purple-400 flex justify-end">
      <div className="flex xl:flex-row flex-col py-5 max-w-screen-xl justify-end xl:pl-56">
        <img
          src={project.image}
          alt="portfolio img"
          className="relative bottom-20 -mb-14 drop-shadow-2xl w-[500px] xl:h-[300px] z-10 p-3"
        />

        <article className="flex flex-col items-start mx-5">
          <a href={project.url} target="blank_" className="text-white mb-5 text-2xl md:text-4xl xl:py-3 relative font-semibold rounded-lg notranslate xl:text-left text-right
          before:transform before:h-[2px] before:bottom-2 before:w-0 before:bg-white before:absolute xl:before:hover:w-full before:transition-all before:origin-right">
            {project.name}
          </a>
          <p className="text-white text-md md:text-xl text-left">
            {project.description}
          </p>
        </article>
      </div>
      
    </div>
  );
};
