import { LandingPage } from "./pages/LandingPage";
import "./App.css";

function App() {
  return (
    <div className="flex w-full items-center text-center flex-col scroll-smooth">
      <LandingPage />
    </div>
  );
}

export default App;