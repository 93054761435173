import { ProjectProps } from "./ProjectLeft";

export const ProjectRight = ({ project }: ProjectProps) => {
  return (
    <div className="xl:w-screen py-5 xl:h-min relative xl:left-1/2 transform xl:-translate-x-[400px] bg-gradient-to-l from-purple-600 to-red-500 rounded-2xl shadow-lg shadow-purple-400 flex justify-start">
      <div className="flex xl:flex-row flex-col py-5 max-w-screen-xl justify-end xl:pr-56 ">
        <article className="order-2 xl:order-1 flex flex-col xl:items-end items-start mx-5">
          <a href={project.url} target="blank_" className="text-white mb-5 text-2xl md:text-4xl xl:py-3 relative font-semibold rounded-lg notranslate xl:text-right text-left
          before:transform before:h-[2px] before:bottom-2 before:w-0 before:bg-white before:absolute xl:before:hover:w-full before:transition-all before:origin-left">
            {project.name}
          </a>
          <p className="text-white text-md md:text-xl xl:text-right text-left">
            {project.description}
          </p>
        </article>
        <img
          src={project.image}
          alt="portfolio img"
          className="order-1 xl:order-2 relative bottom-20 -mb-14 drop-shadow-2xl w-[500px] xl:h-[300px] z-10 p-3"
        />
        
      </div>
    </div>
  );
};
