import duck from "../assets/ducks/yellow_duck.png";

export const DuckRaise = () => {
  return (
    <div className="w-full h-full -z-1 absolute  overflow-hidden">
      <div className="opacity-30 h-['100vh'] ">
        <div
          className={
            "animate-[ascend__ease-in-out_6s_infinite] absolute bottom-0 w-52 h-52"
          }
        >
          <img src={duck} alt="duck" className="animate-wiggle" />
        </div>
        <div
          className={
            "animate-[ascend__ease-in-out_16s_infinite] absolute bottom-0 w-1/4 h-1/4 left-96"
          }
        >
          <img src={duck} alt="duck" className="animate-wiggle" />
        </div>
        <div
          className={
            "animate-[ascend__ease-in-out_8s_infinite] absolute bottom-0 w-96 h-96 left-3"
          }
        >
          <img src={duck} alt="duck" className="animate-wiggle" />
        </div>
        <div
          className={
            "animate-[ascend__ease-in-out_12s_infinite] absolute bottom-0 w-20 h-20 right-10"
          }
        >
          <img src={duck} alt="duck" className="animate-wiggle" />
        </div>
        <div
          className={
            "animate-[ascend__ease-in-out_12s_infinite] absolute bottom-0 w-2/5 h-2/5 right-32"
          }
        >
          <img src={duck} alt="duck" className="animate-wiggle" />
        </div>
      </div>
    </div>
  );
};
