import { useState, useRef, useEffect } from "react";
import { NavLink } from "./Header";

interface HambMenuProps {
  links: NavLink[];
  handleShowContactForm: () => void;
}

export const HamburgerMenu = ({ links, handleShowContactForm }: HambMenuProps) => {
  const [open, setOpen] = useState<Boolean>(false);
  const menu = useRef(null);

  const handleMenuClick = () => {
    setOpen(!open);
  };

  const handleGetInTouch = () => {
    setOpen(!open);
    handleShowContactForm()
  }

  const menuTransition = open
    ? { transform: "translate(-50%,0%)", transition: "transform 300ms ease-in-out" }
    : { transform: "translate(-50%,-100%)", transition: "transform 300ms ease-in-out" };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open])

  return (
    <div className="xl:hidden mx-5 ">
      <button
        tabIndex={1}
        ref={menu}
        className="relative group xl:hidden"
        onClick={handleMenuClick}
      // onBlur={() => setTimeout(() => setOpen(false), 100)} // otherwise options are not clickable, it loses focus instantly
      >
        <div className="z-20 relative w-6 transition-all">
          <div className="justify-between h-4 w-full transform transition-all duration-300 origin-center child:hover:bg-yellow-500">
            <div className={`bg-white h-1 w-full absolute top-0 rounded-full transform transition-all duration-300 origin-center ${open ? "rotate-45 top-4" : ""}`}></div>
            <div className={`bg-white h-1 w-full absolute top-1/2 rounded-full transform transition-all duration-300 ${open ? "-translate-x-5 opacity-0" : ""}`}></div>
            <div className={`bg-white h-1 w-full absolute top-full rounded-full transform transition-all duration-300 origin-center ${open ? "-rotate-45 top-2" : ""}`}></div>
          </div>
        </div>
      </button>

      <div className="absolute w-full h-[100vh]  flex justify-center gap-20 items-center flex-col top-0 left-1/2 transform -translate-x-1/2 bg-gradient-to-tr from-red-700 to-violet-700 backdrop-blur-sm text-xl font-semibold rounded-md"
        style={menuTransition}>

        {links.map((link, index) => (
          <a href={link.path}
            key={index}
            onClick={handleMenuClick}
            className="whitespace-nowrap py-5 px-10 text-white hover:text-yellow-500 transition-all cursor-pointer  rounded-full ">
            {link.text}
          </a>
        ))}

        <div
          onClick={handleGetInTouch}
          className="active:translate-y-1 bg-yellow-500 border-2 border-yellow-500 hover:bg-transparent text-violet-600 hover:text-yellow-500 rounded-md px-3 py-1 font-bold cursor-pointer transition-all">
          Get in touch
        </div>

      </div>
    </div>
  );
};
