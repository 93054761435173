import duck from "../../assets/ducks/duck_text_mono.svg";

export const Footer = () => {

  return (
    <footer className="p-3 bg-slate-800 text-slate-400 w-full min-h-[50px] flex justify-between items-center flex-col sm:flex-row notranslate">
      <div className="flex items-center">
        <img src={duck} alt="duck" className="h-5" />
      </div>
      <span className="ml-1 font-bold cursor-default text-xs mt-5 sm:mt-0">Copyright © DuckFoo {new Date().getFullYear()}. All rights reserved.</span>

      <section className="ml-1 font-bold cursor-default text-xs mt-5 sm:mt-0 sm:mb-0">duckfoodev@gmail.com</section>
    </footer>
  );
};