import { DuckRaise } from "../components/DuckRaise";
import { Header } from "../components/header/Header";
import { Presentation } from "../components/presentation/Presentation";
import ArrowDownIcon from "../assets/arrow-down-icon.svg";
import closeButton from "../assets/closeButton.svg";
import { Footer } from "../components/footer/Footer";

import { CardContainer } from "../components/cardsContainer/CardContainer";
import { ContactForm } from "../components/ContactForm";
import { useEffect, useState } from "react";
import { ProjectContainer } from "../components/projectsContainer/ProjectContainer";

export const LandingPage = () => {
  const [contactFormOpen, setContactFormOpen] = useState<boolean>(false);

  const handleShowContactForm = () => {
    setContactFormOpen((prev) => !prev);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    document.body.style.overflow = contactFormOpen ? "hidden" : "unset";
  }, [contactFormOpen]);

  return (
    <div className="relative flex flex-col justify-center items-center w-full overflow-hidden ">
      <Header handleShowContactForm={handleShowContactForm} />

      <div className="z-0 w-screen flex justify-center bg-gradient-to-tr from-red-700 to-violet-700 ">
        <div className=" w-full max-w-screen-xl">
          <div
            className="grid content-center h-screen overflow-hidden
                          md:pt-32 
                          xl:pt-0 xl:content-center 
                          landscape:content-center landscape:pt-0"
          >
            <Presentation />
          </div>
        </div>

        <div className="w-screen h-screen absolute -z-10">
          {/* this is absolute and takes the form of it's container  */}
          <DuckRaise />
        </div>

        {/* Floating bouncing button */}
        <div
          className="animate-bounce absolute top-[79vh] md:top-[86vh] xl:top-[90vh] cursor-pointer hidden lg:block"
          onClick={() =>
            window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
          }
        >
          <img className="w-14 h-14" src={ArrowDownIcon} alt="arrow down" />
        </div>
      </div>

      {/* OTRAS SECCIONES */}
      <div
        id="about"
        className="flex justify-center align-center w-full py-24 bg-purple-200"
      >
        <div className="flex justify-center max-w-screen-xl">
          <CardContainer />
        </div>
      </div>

      <div
        id="producks"
        className="flex flex-col gap-20 justify-center items-center w-full py-24 bg-purple-100 shadow-sm shadow-purple-50"
      >
        <div className="flex justify-center max-w-screen-xl xl:w-2/3">
          <ProjectContainer />
        </div>
        <button
          onClick={handleShowContactForm}
          className="xl:hidden w-min active:translate-y-1 whitespace-nowrap bg-yellow-300 border-2 border-yellow-300 hover:bg-transparent text-violet-600 hover:text-yellow-300 rounded-md px-5 py-2 font-bold cursor-pointer transition-all"
        >
          Get in touch
        </button>
      </div>

      {/* <div id="contact" className=" hidden xl:flex justify-center align-center w-full py-24 bg-purple-200">
        <div className="flex justify-center max-w-screen-xl w-2/3">
          <ContactForm closeModal={setContactFormOpen} />
        </div>
      </div> */}

      <Footer />

      {/* MODALS'N ABSOLUTE STUFF */}

      {contactFormOpen && (
        <div className="z-50 flex justify-center items-center absolute top-0 left-0 h-screen w-screen bg-gray-700 bg-opacity-60 backdrop-blur-md">
          <div
            className="relative w-full h-full flex justify-center items-center xl:h-min xl:w-2/3">
            <div
              className="bg-purple-400 rounded-full hover:bg-purple-700 transition-all absolute w-12 h-12 top-6 right-6 text-white text-8xl cursor-pointer
                            xl:hover:opacity-100 xl:opacity-60"
              onClick={handleShowContactForm}
            >
              <img src={closeButton} alt="closeButton" className="p-3" />
            </div>
            <ContactForm hideShadow={true} closeModal={setContactFormOpen} />
          </div>
        </div>
      )}
    </div>
  );
};
