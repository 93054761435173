import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

interface FormInput {
  user_name: string;
  user_email: string;
  message: string;
}

const initialFormInput = {
  user_name: "",
  user_email: "",
  message: "",
};

interface ContactFormProps {
  hideShadow?: boolean,
  closeModal: (a: boolean) => void,
}

interface SubmitMessage {
  text: string,
  error: boolean
}

export const ContactForm = (props: ContactFormProps) => {
  const [formInput, setFormInput] = useState<FormInput>(initialFormInput);
  const [submitMessage, setSubmitMessage] = useState<SubmitMessage>({ text: "", error: false })
  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerHeight < 540)


  window.addEventListener("resize", function (e) {
    setIsLandscape(window.innerHeight < 540);
  });

  const form = useRef<HTMLFormElement>({} as HTMLFormElement);

  const handleChangeInput = ({ target }: any) => {
    setFormInput({ ...formInput, [target.name]: target.value });
  };

  const shadowStyle = {
    boxShadow: props.hideShadow ? "0px 0px 0px #000" : "",
    background: props.hideShadow ? undefined : "#d8b4fe",
  }


  const sendEmail = (e: any) => {
    e.preventDefault();

    const service_id = "service_zex6qt4";
    const template_id = "template_z2n4o4j";

    setSubmitMessage({ text: `Sending message...`, error: false })

    emailjs
      .sendForm(service_id, template_id, form.current, "uK06OBmQuBotg3Nb7")
      .then((result) => {
        setSubmitMessage({ text: `Message sent succesfully, thank you ${formInput.user_name}`, error: false })
        setFormInput(initialFormInput)
      },
        (error) => {
          setSubmitMessage({ text: `We are sorry ${formInput.user_name}, this message couldn't be sent. Please try again`, error: true })
        }
      );
  };


  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      className="w-full h-full py-10 rounded-lg bg-gradient-to-tr from-red-400 to-purple-600 flex flex-col justify-center items-center shadow-xl shadow-purple-400"
      style={shadowStyle}
    >
      <div className="flex xl:flex-row flex-col w-4/5 justify-around">
        <div className="flex flex-col items-start xl:w-1/3 xl:mb-0 mb-4">
          <label className="uppercase font-bold text-sm text-purple-900">
            Name
          </label>
          <input
            className="focus:outline-purple-500 p-2 rounded-md w-full focus:bg-purple-100 transition-colors"
            type="text"
            name="user_name"
            aria-label="name"
            value={formInput.user_name}
            onChange={handleChangeInput}
            required
          />
        </div>

        <div className="flex flex-col items-start xl:w-1/3">
          <label className="uppercase font-bold text-sm text-purple-900">
            Email
          </label>
          <input
            className="focus:outline-purple-500 p-2 rounded-md w-full focus:bg-purple-100 transition-colors"
            type="email"
            name="user_email"
            aria-label="email"
            value={formInput.user_email}
            onChange={handleChangeInput}
            required
          />
        </div>
      </div>

      <div className="flex flex-col items-start w-4/5 mt-8">
        <label className="uppercase font-bold text-sm text-purple-900">
          Message
        </label>
        <textarea
          className="focus:outline-purple-500 p-2 rounded-md w-full resize-none focus:bg-purple-100 transition-colors"
          rows={isLandscape ? 3 : 8}
          name="message"
          aria-label="message"
          value={formInput.message}
          onChange={handleChangeInput}
          required
        />
      </div>
      {submitMessage.text && submitMessage.error
        ? <span className="text-red-900">{submitMessage.text}</span>
        : <span className="text-green-800">{submitMessage.text}</span>}
      <input

        className="py-2 px-4 bg-purple-900 hover:bg-purple-700 text-white my-5 rounded-md transition-all transform active:translate-y-1 cursor-pointer"
        type="submit"
        value="Send message"
      />
    </form>
  );
};
