import { FloatingDuck } from "./FloatingDuck";

export const Presentation = () => {
  return (
    <div
      tabIndex={1}
      className="grid gap-8 xl:gap-0 landscape:gap-0 landscape:text-5xl landscape:lg:text-8xl content-center grid-cols-1  xl:px-20 font-bold text-white text-5xl md:text-7xl xl:text-8xl "
    >
      <div className="flex flex-col items-center justify-center whitespace-nowrap">
        <div className="flex flex-col md:flex-row"><div> Let our&nbsp;</div><div><span className="text-yellow-300">Team</span></div></div>
        <div className="flex flex-col md:flex-row"><div> build your&nbsp;</div> <div>Pro<span className="text-yellow-300">Ducks</span></div>
        </div>
      </div>

    </div>
  );
};
