import { CardType, Card } from "./Card";

import ResponsiveIcon from "../../assets/responsiveIcon.svg"
import LatestTechIcon from "../../assets/latestTech.svg"
import seoImg from "../../assets/seoImage.svg"
import mobileImg from "../../assets/mobile-card.png"


const cards: CardType[] = [
  {
    title: "Web Apps",
    text: "We will craft amazing websites for you and your audience working on both Browser and Mobile devices according to your needs.",
    image: ResponsiveIcon,
  },
  {
    title: "Improve SEO & Analytics",
    text: "Level up your SEO to be on the top of Google searches with good analytics to understand your audience and boost your business.",
    image: seoImg,
  },
  {
    title: "Android & iOS apps",
    text: "Put your ideas on your customer's hands with awesome apps working on Android and iOS.",
    image: mobileImg,
  },
  {
    title: "Latest technologies",
    text: "Always using the latest technologies to be one step ahead of the future...",
    image: LatestTechIcon,
  },
];

export const CardContainer = () => {
  return (
    <div className="xl:w-4/5 w-3/4 gap-16 grid grid-cols-1 xl:grid-cols-2">
      {cards.map((card, index) => (
        <Card info={card} key={index} />
      ))}
    </div>
  );
};
