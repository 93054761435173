import { ProjectLeft } from "./ProjectLeft";
import { ProjectRight } from "./ProjectRight";

import regulAppImg from "../../assets/projectImgs/regul-app.png";
import sinPalabrasImg from "../../assets/projectImgs/sin-palabras.png";
import minesweeperImg from "../../assets/projectImgs/minesweeper.png";
import pixelPerfectImg from "../../assets/projectImgs/pixel-perfect-pallete.png";

export interface Project {
  name: string;
  image: any;
  description: string;
  url?: string;
}

const projectList: Project[] = [
  {
    name: "Cambio Regul S.A.",
    image: regulAppImg,
    description:
      "A handcrafted website for a currency exchange business, using Cloud Storage where the administrator can upload files, documents and change currency prices directly from the website by logging in with an user. Costumers also can send mails via the Contact page filling the contact form. Featuring a user friendly responsive design to fit all devices and needs.",
    url: "https://cambioregulsa.com",
  },
  {
    name: "Sin Palabras",
    image: sinPalabrasImg,
    description:
      'A carefully crafted website for the Uruguayan Alternative Rock band "Sin Palabras", Showcasing all it\'s songs, singles and videos, with attention to all the details to generate a great user experience.',
    url: "https://www.sinpalabras.com.uy/",
  },
  {
    name: "Minesweeper",
    image: minesweeperImg,
    description:
      "A dark mode design of the old well-known 'Minesweeper', to play with any amount of bombs, columns and rows you'd like, to challenge yourself and fit any difficulty, a great way to relax while defusing those mines.",
    url: "https://nicolasdehorta.github.io/minesweeper/",
  },
  {
    name: "Pixel Perfect Pallete",
    image: pixelPerfectImg,
    description:
      "An application oriented to videogame development to get the pallete from any image PNG and be able to change any of its colors and download the new image with all the changes made, specially designed to easily get different characters or assets by just changing its colors.",
    url: "https://jhonamath.github.io/pixel-art-pallete-generator/",
  },
];

export const ProjectContainer = () => {
  return (
    <div className="flex flex-col xl:w-screen w-[85%] gap-28 pt-10">
      {projectList.map((project: Project, index: number) =>
        index % 2 === 0 ? (
          <ProjectLeft key={index} project={project} />
        ) : (
          <ProjectRight key={index} project={project} />
        )
      )}
    </div>
  );
};
