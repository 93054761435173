import { useEffect, useState } from "react";
import LogoWithText from "../../assets/ducks/duck_text.svg";
import { HamburgerMenu } from "./HamburgerMenu";

export interface NavLink {
  text: string;
  path: string;
}

const navLinks: NavLink[] = [
  {
    text: "About Us",
    path: "#about",
  },
  {
    text: "ProDucks",
    path: "#producks",
  },
  // {
  //   text: "Daily Duck",
  //   path: "#moreducks",
  // },
];

interface HeaderProps {
  handleShowContactForm: () => void;
}

export const Header = ({ handleShowContactForm }: HeaderProps) => {
  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const scrollLimit = 100;
    // setShowBackground(window.scrollY > scrollLimit)

    window.addEventListener("scroll", function (e) {
      setShowBackground(
        window.scrollY > scrollLimit && document.body.clientWidth > 1280
      );
    });

    window.addEventListener("resize", function (e) {
      setShowBackground(
        window.scrollY > scrollLimit && document.body.clientWidth > 1280
      );
    });
  }, []);

  const onDuckClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header
      id="header"
      className={`xl:fixed absolute top-0 left-0 w-full z-10 flex justify-center max-w-full transition-all duration-500 ${
        showBackground ? "bg-purple-700 bg-opacity-80 backdrop-blur-xl" : ""
      }`}
    >
      <nav
        className={`pt-4 pb-4 flex px-5 justify-between items-center select-none w-full max-w-screen-xl`}
      >
        <div
          className="flex items-center notranslate transform hover:scale-105 transition-all cursor-pointer select-none active:translate-y-1"
          tabIndex={1}
          onClick={onDuckClick}
        >
          <img src={LogoWithText} alt="duck" className=" h-8 drop-shadow-md" />
        </div>

        <div className="hidden xl:flex items-center gap-5 text-base">
          {navLinks.map((link, index) => (
            <a
              href={link.path}
              key={index}
              tabIndex={1}
              className=" active:translate-y-1 text-white font-semibold cursor-pointer hover:text-yellow-300 transition-all"
            >
              {" "}
              {link.text}{" "}
            </a>
          ))}

          <button
            tabIndex={1}
            onClick={handleShowContactForm}
            className="active:translate-y-1 bg-yellow-300 border-2 border-yellow-300 hover:bg-transparent text-violet-600 hover:text-yellow-300 rounded-md px-3 py-1 font-bold cursor-pointer transition-all"
          >
            Get in touch
          </button>
        </div>

        <HamburgerMenu
          links={navLinks}
          handleShowContactForm={handleShowContactForm}
        />
      </nav>
    </header>
  );
};
